@import "../../styles/scss/globals.scss";

.copyrightFooter {
    margin-top: 3rem;
    @include max(991) {
        // max-width: 92.5%;
        margin-left: auto;
        margin-right: auto;
    }
    .logo {
        max-width: 8.125rem;
    }
    .copyrightText {
        color: $grey;
        font-weight: 500;
        margin: 0.5rem auto;
    }

    .langDropdown {
        border: none;
    }
    .langDropdown div.dropdown-menu {
        box-shadow: 0 4px 0.5rem rgba(0, 15, 25, 0.16);
        border: none;
    }
    .langDropdown > a  {
        padding-left: 0;
        padding-right: 0;
    }
    .langDropdown a {
        color: #928c9b;
    }
    .langDropdown .langItem {
        &:hover {
            background-color: #f2f2f2 !important;
            color: #000;
        }
        &:active, &.active {
            background-color: #00e78e !important;
            color: #000;
        }
    }
    .langDropdown .langItem.active{
        background-color: #00e78e !important;
        color: #000;
    }
}

.mainFooterWrap {
    padding: 5rem 2rem;
    position: relative;
    @include max(767) {
        padding: 2rem 2rem 3rem;
        // z-index: -1;
    }
}

.mainFooter {
    @extend .copyrightFooter;
    // .copyrightText {
    //     font-size: 1.25rem;
    // }
    margin-top: 2.5rem !important;
    .footerLink {
        @extend .copyrightText;
        font-size: 1.25rem;
        &:hover, &:focus, &:active {
            color: $primary;
        }
    }
    .singleCol {
        @include min(992) {
            &:first-child {
                padding-left: 0;
            }
        }
        @include max(991) {
            padding-left: 0;
        }
    }
    .spaceCol {
        padding-top: 1rem;
        &.socialMedia {
            padding-top: 0.5rem;
        }
    }
    .langDropdown {
        border: none;
    }
    .langDropdown div.dropdown-menu {
        box-shadow: 0 4px 0.5rem rgba(0, 15, 25, 0.16);
        border: none;
    }
    .langDropdown > a  {
        padding-left: 0;
        padding-right: 0;
    }
    .langDropdown a {
        color: #928c9b;
    }
    .langDropdown .langItem {
        &:hover {
            background-color: #f2f2f2 !important;
            color: #000;
        }
        &:active, &.active {
            background-color: #00e78e !important;
            color: #000;
        }
    }
    .langDropdown .langItem.active{
        background-color: #00e78e !important;
        color: #000;
    }
}

.socialIconsWrap {
    margin-top: 0.75rem;
    margin-bottom: 1.95rem;
    .singleIconWrap {
        margin-right: 1.75rem;
        margin-top: 1rem;
        display: inline-block;
        &:hover, &:focus {
            opacity: 0.6;
        }
        &:last-child{
            margin-right: 0;
        }
    }
    .singleIcon {
        width: auto;
        max-width: 100%;
        height: 1.5rem;
    }
    .discordIcon{
        height: 1.6rem;
    }
}

// Responsive begin

@include max(767) {
    .mainFooterWrap {
        // padding: 5rem 0.5rem;
    }
}
